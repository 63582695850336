import React from "react"
// import PropTypes from 'prop-types'
// import styled from 'styled-components'
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"
import SliceZone from "components/SliceZone"
import { linkResolver } from "utils/linkResolver"

function Page({ data: { prismic, site }, ...props }) {
  const { page, site_settings } = prismic
  if (!page) return null
  const { body } = page
  const {
    meta_title,
    meta_description,
    meta_images,
    _meta: { lang },
  } = page
  const { siteUrl, ...defaultMeta } = site.defaultMeta
  const uri = linkResolver(page._meta)

  const onPageNav = body
    .map((s) => {
      if (s.primary?.anchor_title) {
        return {
          anchor: s.primary.anchor_title,
        }
      }
      return
    })
    .filter(Boolean)

  return (
    <>
      <SEO
        lang={lang}
        slug={uri}
        title={meta_title}
        description={meta_description}
        images={meta_images}
        defaultMeta={defaultMeta}
        hideFromGoogle
      />
      <Layout site_settings={site_settings} lang={lang} pageNav={onPageNav}>
        <SliceZone
          slices={body}
          pageInfo={{ uri, host: siteUrl, title: page.title[0].text }}
        />
      </Layout>
    </>
  )
}

export default Page

Page.defaultProps = {}

Page.propTypes = {}

export const query = graphql`
  query PrismicPage($lang: String!, $uid: String!, $isProduction: Boolean!) {
    site {
      defaultMeta: siteMetadata {
        ...SiteMetaFragment
      }
    }
    prismic {
      site_settings(lang: $lang, uid: "site_settings") {
        copyright_information
      }
      page(lang: $lang, uid: $uid) {
        _meta {
          type
          lang
          uid
        }
        title
        meta_title
        meta_description
        meta_images {
          image
          imageSharp @include(if: $isProduction) {
            childImageSharp {
              id
              main: fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        show_in_sitemap
        body {
          ... on PRISMIC_PageBodyParagraph___image {
            type
            primary {
              use_anchor
              anchor_title
              image_position
              content
              content_image_positions
              paragraph_image
              imageSharp: paragraph_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  mobile: fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyHero_section {
            type
            label
            primary {
              hero_image
              imageSharp: hero_imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1920, height: 1080) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480, height: 660) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              mobile_grid
              tablet_portrait_grid
              tablet_landscape_grid
              graphics_container
              align_content
            }
            fields {
              graphic
              grid_position
              justify_graphic
              align_graphic
              sizes
            }
          }
          ... on PRISMIC_PageBodyFeature_repeater {
            type
            label
            primary {
              use_anchor
              anchor_title
              features_content
              features_title
              features_content_after
            }
            fields {
              feature_description
              feature_icon
            }
          }
          ... on PRISMIC_PageBodyParallax_background {
            type
            primary {
              visible_height
              image
              imageSharp: imageSharp @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1920, height: 1080) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyProduct_repeater {
            type
            label
            primary {
              theme_color
              use_anchor
              anchor_title
              product_columns
              products_title
              products_content
              product_catalogue_image
              imageSharp: product_catalogue_imageSharp
                @include(if: $isProduction) {
                childImageSharp {
                  id
                  desktop: fixed(width: 1048, height: 210) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  mobile: fixed(width: 480, height: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
              product_catalogue_icon
            }
            fields {
              product {
                ... on PRISMIC_Product {
                  ...ProductFragment
                }
              }
              product_icon
            }
          }
          ... on PRISMIC_PageBodyVideo_repeater {
            type
            label
            primary {
              use_anchor
              anchor_title
              video_columns
              videos_title
              videos_content
            }
            fields {
              youtube_url
              video_description
            }
          }
        }
      }
    }
  }
`
